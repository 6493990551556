
import React from "react";
const Loading = ()=>{
    return (
        <React.Fragment>         
      <div className="father-load" >
        <div className="load"></div>
        <h2 className="load-word">Loading</h2>
      </div>
        </React.Fragment>
      );
};
export default Loading;