import React from 'react';
import ReactDOM from 'react-dom';
import "./sass/main.scss"
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// ReactDOM.render(
//   <BrowserRouter >
//     <App />
//   </BrowserRouter>,
//   document.getElementById('root')
// );


// "eslintConfig": {
//   "extends": "react-app",
//   "plugins": [
//     "jsx-a11y"
//   ],

