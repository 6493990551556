import React from "react";
import Header from "./Header";
const DesktopNavbar = ()=>{

    return(
        <React.Fragment>
            <div className="desktopHeader">
            < Header  /> 
            </div>
</React.Fragment>   
    )
}
export default DesktopNavbar;