import React from "react";
import HomeSub from "./HomeSub";

const Home =()=>{
    return (
      <React.Fragment>
        <HomeSub />
      </React.Fragment>
    )
}
export default Home;